<template>
  <!-- 
    this template requires the following font:
    @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
   -->

  <div class="header-container">
    <!-- heading -->
    <div class="heading-container">
      <!-- author -->
      <div class="author-container">
        <img
          class="avatar"
          :src="author.avatar"
          :alt="`${author.name}'s avatar`"
        />
        <span class="author-name">{{ author.name }}</span>
      </div>

      <!-- post date -->
      <span class="meta">{{ meta.created_at }}</span>
    </div>

    <!-- title & tags -->
    <div>
      <!-- tags -->
      <div class="tags-container">
        <span
          v-for="tag in tags"
          :key="tag.title"
          class="tag"
          :class="[tag.background, tag.foreground]"
        >
          {{ tag.title }}
        </span>
      </div>

      <h1 class="title">{{ title }}</h1>
    </div>

    <!-- brief -->
    <h2 class="brief">{{ brief }}</h2>

    <!-- actions -->
    <div class="actions-container">
      <!-- CTA buttons -->
      <div class="cta-container">
        <button class="cta-button">
          <ShareIcon class="w-5 h-5" />
          <span>Share</span>
        </button>
        <button class="cta-button">
          <HeartIcon class="w-5 h-5" />
          <span>Like</span>
        </button>
      </div>

      <!-- meta -->
      <div class="meta-container">
        <!-- comments -->
        <div class="flex space-x-1 items-center">
          <ChatIcon class="h-5 w-5 meta" />
          <span class="meta">{{ meta.comments }}</span>
        </div>

        <!-- views -->
        <div class="flex space-x-1 items-center">
          <EyeIcon class="h-5 w-5 meta" />
          <span class="meta">{{ meta.views }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  ChatIcon,
  EyeIcon,
  HeartIcon,
  ShareIcon,
} from '@heroicons/vue/outline';

export default {
  name: 'basic header 2',
  components: { ChatIcon, EyeIcon, HeartIcon, ShareIcon },
  data() {
    return {
      title: 'Chef Carlos Gaytan Wants You to Think Outside',
      brief:
        "There's more to an avocado — and avocado french fries are one delicious way to get your fix.",
      tags: [
        {
          title: 'movies',
          background: 'bg-red-100',
          foreground: 'text-red-700',
        },
        {
          title: 'food',
          background: 'bg-green-100',
          foreground: 'text-green-700',
        },
        {
          title: 'gossip',
          background: 'bg-pink-100',
          foreground: 'text-pink-700',
        },
      ],
      author: {
        name: 'Curtis Thronton',
        avatar:
          'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
      },
      meta: {
        created_at: '04 Jun 2018',
        comments: 81,
        views: 719,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.header-container {
  @apply bg-white w-full flex flex-col space-y-7 md:space-y-10 p-7 sm:p-10 md:p-20;

  .heading-container {
    @apply flex space-x-5 items-center;

    .author-container {
      @apply flex space-x-2 items-center;

      .avatar {
        @apply inline-block w-8 h-8 sm:h-10 sm:w-10 rounded-full object-cover;
      }

      .author-name {
        @apply font-bold text-sm sm:text-base;
      }
    }
  }

  .tags-container {
    @apply flex space-x-2 items-center pb-1;

    .tag {
      @apply rounded-lg px-2 py-1 text-xs font-semibold uppercase;
    }
  }

  .title {
    @apply text-2xl sm:text-4xl md:text-6xl lg:text-7xl font-semibold max-w-4xl;
  }

  .brief {
    @apply text-lg font-semibold text-gray-500 max-w-xl;
  }

  .actions-container {
    @apply flex justify-between sm:justify-start space-x-3 sm:space-x-10 items-center;

    .cta-container {
      @apply flex space-x-2 items-center;

      .cta-button {
        @apply flex space-x-2 border border-gray-200 rounded-md px-3 py-2 font-bold text-sm text-gray-500 hover:text-gray-900;
      }
    }

    .meta-container {
      @apply flex space-x-2 items-center;
    }
  }

  .meta {
    @apply text-sm font-semibold text-gray-400;
  }
}
</style>
